<template>
   <v-col
      v-if="($vuetify.breakpoint.mdAndUp && modal.laptopCols>0) || ($vuetify.breakpoint.smAndDown && modal.mobileCols>0)"
      :cols="$vuetify.breakpoint.mdAndUp?modal.laptopCols:modal.mobileCols"
      class="pa-1"
      :style="{'max-width': modal.width&&$vuetify.breakpoint.mdAndUp?modal.width:'none'}">
      <v-card class="pa-0">
         <v-card-title class="pa-0 pl-4 pr-1 pb-1"
            style="min-height:48px;font-weight:700;color:#546E7A;font-size:16px;line-height:1.9;border-bottom:1px solid #DDDDDD;">
            {{modal.title}} 
            <v-btn text v-if='modal.showDownload' 
               @click='exportExcel'
               class='text-none ml-2' color='teal'>
               <b>Tải xuống</b></v-btn>
            <v-spacer/>
            <date-picker
               v-model='dateType' 
               defaultActiveType='day'
               @dateChanged='onDateChanged'/>
         </v-card-title>
         <v-card-text class="pa-0">
            <v-row class="ma-0 px-0">
               <v-col class="pa-0" align="center" justify="center">
                  <v-card class="pa-1">
                     <!-- <v-card-title class="pt-1 pb-0 ma-0">
                        <v-btn color="primary" class="text-none px-2" @click="exportExcel">
                           <v-icon class="mr-1">mdi-download</v-icon>
                           Download
                        </v-btn>
                        <v-spacer/>
                        <v-btn fab color="primary" x-small :outlined="chartScale!=1" 
                           @click="chartScale=1"
                           class="mr-2">
                           1x
                        </v-btn>
                        <v-btn fab color="primary" x-small :outlined="chartScale!=2" 
                           @click="chartScale=2"
                           class="mr-2">
                           2x
                        </v-btn>
                        <v-btn fab color="primary" x-small :outlined="chartScale!=4"
                           @click="chartScale=4"
                           class="mr-2"
                           >
                           4x
                        </v-btn>
                        <v-btn fab color="primary" x-small :outlined="chartScale!=8"
                           @click="chartScale=8"
                           >
                           8x
                        </v-btn>
                     </v-card-title> -->
                     <v-card-text class='pa-0 pb-2 pt-1 ma-0'>
                        <line-chart-points v-if="modal && modal.chartType!='table' && hasChartContent"
                           :style="{'height': modal.minHeight?modal.minHeight:'200px'}"
                           :chartType="modal.chartType"
                           :loadIndex="loadIndex"
                           :scale='chartScale'
                           :labels='chartLabels'
                           :dontBeginAtZero='modal.dontBeginAtZero || false'
                           :points='pointsWithData'/>

                        <div v-if="modal && modal.chartType=='table'" class='px-2 x-scrollbar'
                           style='min-height:200px;max-height:460px;overflow-y:auto;'>
                           <div class='d-flex' style='border-bottom:1px solid #EDEDED;' v-if='modal.showSummary && modal.points'>
                              <div v-for="(point, ppIndex) in modal.points" :key='ppIndex'
                                 style='font-size:19px;' class="d-flex-inline pl-0 pr-4 pt-0 pb-1"
                                 :style="{'color': point.color}">
                                    {{point.name||''}}: <b>{{point.count||0}}</b>
                              </div>
                           </div>
                           <table style='width:100%;font-size:15px;'>
                              <tr>
                                 <td style='width:150px;'>Ngày giờ</td>
                                 <td></td>
                                 
                              </tr>
                              <tr v-for='(row, rIndex) in tableData' :key='rIndex'
                                 :style="{'color': row.color}">
                                 <td>{{row.atStr}}</td>
                                 <td>
                                    <span  style='font-weight:600;'>{{row.displayName}}</span>
                                 </td>
                              </tr>
                           </table>
                        </div>
                     </v-card-text>
                  </v-card>
               </v-col>
            </v-row>
         </v-card-text>
      </v-card>
   </v-col>
</template>

<script>
let moment = require('moment')
let XLSX = require("xlsx")
export default {
   props: [
      'modal', 
      'customStyle',
      ],
   watch: {
      // pointsWithData: {
      //    immediate: true,
      //    handler: function () {
      //       if(this.pointsWithData) {
      //          console.log(`this.pointsWithData`)
      //          console.log(this.pointsWithData)
      //       }
      //    }
      // }
   },
   computed: {
      show: function () {
         if (this.mode.electronHide && this.$root.isElectron) return false
         if (this.mode.laptopHide && this.$vuetify.mdAndUp) return false
         if (this.mode.mobileHide && this.$vuetify.smAndDown) return false
         return true
      },
      hasChartContent: function () {
         return (this.pointsWithData && this.pointsWithData.length>0)
      },
   },
   sockets: {
      callHTTPRequestResult: function (payload) {
         // console.log("callHTTPRequestResult")
         // console.log(payload)
         if(payload && payload.askKey == this.socketAskKey) {
            if(payload.result && payload.result.status=="OK"){
               this.blocks = this.blocks.concat(payload.result.content)
               this.processDataBlocksAfterFetch()
            }
         }
      }
   },
   data () {
      return {
         loadIndex: new Date().getTime(),
         chartScale: 1,
         chartLabels: [],
         pointsWithData: null,
         curDateObject: null,
         loading: false,
         socketAskKey: new Date().getTime(),
         dateType: null,
         activeInterval: 60,
         tableData: [],
      }
   },
   methods: {
      async onDateChanged (dateObj) {
         // console.log(dateObj)
         this.curDateObject = dateObj
         if (this.modal.chartType=='bar') {
            this.activeInterval = 60*24
            if(this.curDateObject.type=='day'){
               this.activeInterval = 60
            }
         }
         if (this.modal.chartType=='line') {
            this.activeInterval = 30
            if(this.curDateObject.type=='day'){
               this.activeInterval = 5
            }
         }

         if (this.modal.dataInterval) {
            this.activeInterval = this.modal.dataInterval
         }

         await this.fetchData()
      },
      exportExcel: function () {
         let wb = XLSX.utils.book_new();
         let data = this.tableData;
         let header = ['Ngày giờ', 'Sự kiện']
         data = data.map(row => {
            let returnRow = {}
            returnRow[header[0]] = row['atStr']
            returnRow[header[1]] = row['displayName']
            return returnRow
         })

         let dataWS = XLSX.utils.json_to_sheet(data, {
            header: header
         });
         let wscols = [
            {wpx: 160}
         ]
         header.forEach((f, index) => {if(index>0) wscols.push({wpx: 120})})
         dataWS['!cols'] = wscols
         XLSX.utils.book_append_sheet(wb, dataWS, "Báo cáo");
         const fileName = this.genExcelFileName(this.dateRange);
         XLSX.writeFile(wb, fileName);
      },
      async buildReport () {
         await this.fetchData()
      },
      async fetchData() {
            if(!this.modal.points) return []
            let fetchPath = this.$root.logApi + "blocks/filter"
            let pointNamesHotBlock = this.modal.points.map(p => p.reportName)
            let pointNames = this.modal.points.map(p => 'masterDev-' + p.reportName)
            let fromStr  = moment(this.curDateObject.date).format("YYYY-MM-DD HH:mm:00")
            let toStr = moment(this.curDateObject.toDate).add(-1, 'm').format("YYYY-MM-DD HH:mm:00")

            // console.log(`${fromStr} - ${toStr}`)

            let fromYearStr = this.curDateObject.dateStrFull.substring(0,4)
            let toYearStr = moment(this.curDateObject.toDate).add(-1, 'm').format("YYYY-MM-DD HH:mm:00").substring(0,4)

            let filterArr = []
            if (fromYearStr == toYearStr) {
               this.loading = true
               filterArr.push({
                  includeRec: 'true',
                  from: fromStr,
                  to: toStr,
                  pointNames: pointNames,
                  pointNamesHotBlock: pointNamesHotBlock
               })
            } else {
               filterArr.push({
                  includeRec: 'true',
                  from: fromStr,
                  to: moment(fromStr)
                     .endOf("year")
                     .format("YYYY-MM-DD 23:59:59"),
                  pointNames: pointNames,
                  pointNamesHotBlock: pointNamesHotBlock
               })
               filterArr.push({
                  includeRec: 'true',
                     from: moment(toStr)
                        .startOf("year")
                        .format("YYYY-MM-DD 00:00:00"),
                     to: toStr,
                     pointNames: pointNames,
                     pointNamesHotBlock: pointNamesHotBlock
               })
            }
            this.loading = true
            let returnBlocks = []
            // console.log(filterArr)

            this.blocks = []
            for(let i = 0; i< filterArr.length; i++){
               if (this.$root.isRemote) {
                  try {
                     this.callApiOverOTA(
                        "PUT",
                        fetchPath,
                        filterArr[i]
                        )
                     
                  } catch (err) {
                     console.log(err)
                     if(i==0){
                        alert(err)
                     }
                  }
               } else {
                  let content = await this.fetchBlockDataDirect(filterArr[i])
                  this.blocks = this.blocks.concat(content)
               }
            }

            this.processDataBlocksAfterFetch()
            
            this.loading = false
            return returnBlocks
      },
      processDataBlocksAfterFetch () {
         this.pointsWithData = []
         this.tableData = []
         if(this.modal && this.modal.chartType!='table') {
            if(this.modal.points.length<=0) return
            this.chartLabels = this.buildLabels()
            this.modal.points.forEach((p, index) => {
               this.pointsWithData.push({
                  reportName: p.reportName,
                  displayName: p.name + " (" + p.unit + ")",
                  color: p.color,
                  backgroundColor: p.backgroundColor,
                  active: p.active,
                  dataItems: this.takeDateForPoints(this.blocks, p.reportName, index, p)
               })
            })
         }

         if(this.modal && this.modal.chartType=='table') {
            this.modal.points.forEach(point => {
               point.count=0
               let matchBlocks = this.blocks.filter(b => b.name == point.reportName)
               let lastValue = null
               let processedBlock = []
               for(let i = 0; i<matchBlocks.length; i++) {
               // matchBlocks.forEach(block => {
                  let block = matchBlocks[i]
                  if(processedBlock.includes(block.date)) return
                  let blockDate = moment(block.date, 'YYYY-MM-DD HH:mm:ss').toDate()
                  if(blockDate<this.curDateObject.date || blockDate>this.curDateObject.toDate) return
                  processedBlock.push(block.date)
                  if(block.records && block.records.length>0){
                     block.records.forEach(rec => {
                        if(rec.q >= 0){
                           let rValue = rec.v
                           if(point.scale != undefined){
                              rValue = rValue * point.scale
                           }
                           if(rValue>0) {
                              let mDate = moment(block.date).add(rec.m, 'minutes')
                              if(point.reportName.startsWith('accu')) {
                                 if(lastValue!=null){
                                    if(rValue>lastValue) {
                                       point.count += rValue-lastValue
                                       this.tableData.push({
                                          at: mDate.toDate(),
                                          atStr: mDate.format('HH:mm, DD/MM/YYYY'),
                                          value: rValue-lastValue,
                                          displayName: point.name,
                                          color: point.color
                                       })
                                    }
                                 }
                                 lastValue = rValue
                              } else {
                                 point.count += rValue
                                 this.tableData.push({
                                    at: mDate.toDate(),
                                    atStr: mDate.format('HH:mm, DD/MM/YYYY'),
                                    value: rValue,
                                    displayName: point.name,
                                    color: point.color
                                 })
                              }
                           }
                        }
                     })
                  }
               }
            })
            this.tableData.sort((a,b) => {
               return new Date(b.at) - new Date(a.at);
            })
         }

         

         this.$forceUpdate()
      },
      buildLabels () {
         let labels = []
         let startDate = this.curDateObject.date
         let endDate = new Date()
         let numberOfItems = 0
         if(this.curDateObject.type == 'day') {
            endDate = moment(startDate).add(1, 'days').toDate()
            numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/(this.activeInterval*60000))
            for(let i=0; i<numberOfItems; i++){
               let dateStr = moment(startDate).add(this.activeInterval*i, 'minutes').format('HH:mm')
               labels.push(dateStr)
            }
         }

         if(this.curDateObject.type == 'week') {
            endDate = moment(startDate).add(7, 'days').toDate()
            numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/(this.activeInterval*60000))
            for(let i=0; i<numberOfItems; i++){
               let dateStr = moment(startDate).add(this.activeInterval*i, 'minutes').format('DD HH:mm')
               labels.push(dateStr)
            }
         }

         if(this.curDateObject.type == 'month') {
            endDate = moment(startDate).add(1, 'months').toDate()
            numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/(this.activeInterval*60000))
            for(let i=0; i<numberOfItems; i++){
               let dateStr = moment(startDate).add(this.activeInterval*i, 'minutes').format('DD HH') + 'h'
               labels.push(dateStr)
            }
         }

         if(this.curDateObject.type == 'year') {
            endDate = moment(startDate).add(1, 'years').toDate()
            numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/(this.activeInterval*60000))
            for(let i=0; i<numberOfItems; i++){
               let dateStr = moment(startDate).add(this.activeInterval*i, 'minutes').format('DD/MM')
               labels.push(dateStr)
            }
         }

         if(this.curDateObject.type == 'range') {
            endDate = this.curDateObject.toDate
            numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/(this.activeInterval*60000)) + 1

            for(let i=0; i<numberOfItems; i++){
               let dateStr = moment(startDate).add(this.activeInterval*i, 'minutes').format('DD HH:mm')
               labels.push(dateStr)
            }
         }
         return labels
      },
      takeDateForPoints(items, reportName, pointIndex, point) {
         let retItem = []
         let blockItems = items.filter(p => p.name == reportName)
         if(blockItems && blockItems.length>0){
            let recItems = []
            blockItems.forEach((block) => {
               let blockDate = new Date(block.date)
               
               if(block.records && block.records.length>0){
                  block.records.forEach(rec => {
                     let recDate = moment(blockDate).add(rec.m, 'minutes').toDate()
                     if(rec.q >= 0){
                        let rValue = rec.v
                        if(point.scale != undefined){
                           rValue = rValue * point.scale
                        }
                        if(rValue == 0) rValue = null
                        if(this.modal.maxValue && rValue>this.modal.maxValue){
                           rValue = null
                        }
                        recItems.push({
                           date: recDate,
                           dateInMili: recDate.getTime(),
                           value: rValue
                           // value: Math.round(Math.random()*100)+100*pointIndex
                        })
                     } else {
                        recItems.push({
                           date: recDate,
                           dateInMili: recDate.getTime(),
                           value: null
                           // value: Math.round(Math.random()*100)+100*pointIndex
                        })
                     }
                  })
               }
            })
            // console.log(recItems)
            let startDate = this.curDateObject.date
            let endDate = this.curDateObject.toDate
            let startDateMili  = startDate.getTime()
            let intervalInMili = this.activeInterval*60000
            let numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/intervalInMili)
            for(let i=0; i<numberOfItems; i++){
               // items.push(Math.round(Math.random()*200)+500 + index*100)
               // let matchItem = recItems.find(item => item.dateInMili == startDateMili+intervalInMili*i)
               // let nextItem = recItems.find(item => item.dateInMili == startDateMili+intervalInMili*(i+1))
               let inRangeItems = recItems.filter(item => item.dateInMili >=startDateMili+intervalInMili*i && item.dateInMili <= startDateMili+intervalInMili*(i+1))
               // console.log(`--------- ${i} ------------`)
               // console.log(inRangeItems)
               if(!reportName.startsWith('accu')) {
                  if(inRangeItems.length>0) {
                     retItem.push(inRangeItems[0].value)
                  } else {
                     retItem.push(null)
                  }
               }
               if(reportName.startsWith('accu')) {
                  if(inRangeItems.length>1) {
                     let accuValue = 0
                     for (let j=1;j<inRangeItems.length;j++){
                        if(inRangeItems[j].value>= inRangeItems[j-1].value) {
                           accuValue += inRangeItems[j].value - inRangeItems[j-1].value
                        }
                        if (inRangeItems[j].value < inRangeItems[j-1].value) {
                           accuValue += inRangeItems[j].value
                        }
                     }
                     retItem.push(accuValue)
                  } else if (inRangeItems.length==1) {
                     retItem.push(0)
                  }
                  else {
                     retItem.push(null)
                  }
               }
            }
         }
         return retItem
      },
      callApiOverOTA (method, url, body) {
         this.$socket.emit('callHTTPRequest', 
         { 
            devCode: this.$root.devCode,
            askKey: this.socketAskKey,
            method: method,
            url: url,
            body: body
         })
      },
      async fetchBlockDataDirect (payload) {
         // console.log("Call " +  this.$root.lanLogApi + `blocks/filter`)
         // console.log(payload)
         let res = await this.axios.put(
            this.$root.lanLogApi + `blocks/filter`,
            payload
         )
         // console.log(res.data)

         if (res.data.status == 'OK') {
            return res.data.content
         } else {
            alert(res.data.message)
         }
      },
      genExcelFileName() {
         let filename = ""   
         if(this.points && this.points.length>0){
            filename = this.points[0].name
         }
         if(this.modal.points && this.modal.points.length>0){
            filename = this.modal.points[0].name
         }
         filename = filename +
         '-' +
         new moment(this.curDateObject.date).format("DD-MM-YYYY") +
         ".xlsx";
         return filename;
      },
   }
}
</script>

<style>
   .x-scrollbar {

   }

   .x-scrollbar::-webkit-scrollbar {
      width: 16px !important;
   }

   .x-scrollbar::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
      border-radius: 14px !important;
   }

   .x-scrollbar::-webkit-scrollbar-thumb {
      border-radius: 14px !important;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5) !important;
   }
</style>